#App {
  // background-image: linear-gradient(to bottom, #000000dd, #00000000), url('../images/Blue-TV-Bars-Tiles.png');
  min-height: 100%;
  padding-top: 50px;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 50px;
  color: #fff;
  font-family: 'Muli', sans-serif;

  #logo {
    height: 80px;
    width: 80px;
    background-image: url('./images/WNLogo.png');
    background-size: cover;
    display: block;
    margin: auto;
    margin-bottom: 50px;
  }

  .signOut {
    position: absolute;
    right: 30px;
    top: 20px;
    background-color: #606568;
    height: 40px;
    line-height: 40px;
    border-radius: 3px;
    padding: 0 15px;
    box-shadow: 0 2px 2px #00000022;
    cursor: pointer;
  }

  form {
    background-color: #606568;
    max-width: 450px;
    padding: 40px 20px;
    margin: auto;
    border-radius: 3px;

    h1 {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 30px;
    }

    h2 {
      text-align: center;
      font-size: 24px;
      margin-top: 20px;
      margin-bottom: -10px;
      color: #ffffffbb;
    }

    .subTitle {
      padding-bottom: 5px;
      margin-bottom: 30px;
    }

    label {
      display: block;
      margin-bottom: 6px;
      font-size: 16px;
      margin-top: 15px;

      > span {
        color: #ffffff77;
      }
    }

    a {
      // text-decoration: none;
      color: #aaabb8;
      float: right;
    }

    .StripeElement,
    input {
      border-radius: 3px;
      background-color: #fff;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      font-family: Muli, sans-serif;
      padding: 0 13px;
      box-sizing: border-box;
      border: none;
      width: 100%;
      box-shadow: 0 2px 2px #00000022;
      color: #333;

      &:disabled {
        opacity: 0.5;
      }
    }

    ::placeholder {
      color: #aaabb8;
    }

    button {
      height: 40px;
      border: none;
      border-radius: 3px;
      font-size: 16px;
      background-color: #2A2F32;
      color: #fff;
      outline: none;
      cursor: pointer;
      box-shadow: 0 2px 2px #00000022;
      background-color: #14c022;
      margin-top: 25px;
      width: 100%;

      &.submitted {
        opacity: 0.5;
        pointer-events: none;
      }

      &:disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .message {
      margin-top: 10px;
      display: block;
      text-shadow: 0 1px 2px #00000044;
    }

    .error {
      color: #fb7677;
      margin-top: 10px;
      display: block;
      text-shadow: 0 1px 2px #00000044;
    }

    .success {
      margin-top: 30px;
      display: inline-block;
    }

    .success-small {
      font-size: 13px;
      color: #c7c7c7;
      display: block;
      margin-top: 10px;
    }

    p {
      color: #c7c7c7;
      margin-bottom: 30px;
    }
  }

  #LearnMore {
    margin: auto;
    display: table;
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    text-align: center;
    margin-top: 40px;


  }
}

