form#PaymentForm {
  .StripeElement {
    padding-top: 11px;
  }
}

.spinner {
  top: 50px;
  position: relative;

  &.inline {
    top: 15px;
    height: 30px;

    &:before {
      border: 3px solid #4C4F51;
      border-top-color: #14c022;
    }
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 15px;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 3px solid #606568;
    border-top-color: #14c022;
    animation: spinner 0.7s linear infinite;
  }
}